import { SHOW_MERCHANT } from 'constant/auth';
import showMenu from 'constant/role';
import { FormattedMessage } from 'react-intl';
import { IconWallet } from '@tabler/icons';

const menuWallet = (role: any) => ({
    hidden: showMenu(role, SHOW_MERCHANT),
    id: 'wallets',
    title: <FormattedMessage id="wallets" />,
    type: 'collapse',
    icon: IconWallet,
    children: [
        {
            id: 'wallets',
            title: <FormattedMessage id="wallets" />,
            type: 'item',
            url: '/merchant/wallet',
            breadcrumbs: false
        },
        {
            id: 'Wallet By SubUser',
            title: <FormattedMessage id="Wallet By SubUser" />,
            type: 'item',
            url: '/merchant/wallet/sub-wallet',
            breadcrumbs: false
        }
    ]
});

export default menuWallet;
