const URL = {
    merchant: {
        security: '/merchant/security',
        referral: '/merchant/referral',
        webhook: '/merchant/webhook',
        invoices: {
            list: '/merchant/invoices-list',
            add: '/merchant/invoices-create',
            detail: '/merchant/invoice/'
        },
        fee: '/merchant/fees'
    },
    public: {
        invoice: {
            share: '/invoice/'
        },
        payment: '/pay',
        fee: '/fees',
        listYourCoin: '/list-your-coin'
    },
    linkable: {
        contact: 'https://www.xaigate.com/contact-us/'
    }
};

export default URL;
